import axios from "axios";
import {
    SIGNING_DOCUMENT_SIGNER_PUBLIC_ENDPOINT,
    SIGNING_ENDPOINT,
    SIGNING_PREFILLED_COMPANY_ENDPOINT
} from "@/constants/endpoints";
import SignerDTO from "@/dto/signature/SignerDTO";
import DeclineSignerPayload from "@/dto/signature/DeclineSignerPayload";
import QueryUtils from "@/utils/QueryUtils";
import authHeader from "@/services/auth-header";
import Page from "@/dto/taxation/Page";
import SignableDocumentsDTO from "@/dto/signature/SignableDocumentsDTO";
import SignableDocumentsFilter from "@/dto/signature/SignableDocumentsFilter";
import CreatePrefilledCompanyDocumentPayload from "@/dto/signature/CreatePrefilledCompanyDocumentPayload";

export default new class SignatureService {

    public getSigner(publicId: string) {
        return axios.get<SignerDTO>(`${SIGNING_DOCUMENT_SIGNER_PUBLIC_ENDPOINT}/${publicId}`)
    }

    public sign(publicId: string, payload: FormData) {
        return axios.post(`${SIGNING_DOCUMENT_SIGNER_PUBLIC_ENDPOINT}/${publicId}/sign`, payload)
    }

    public declineSigner(publicId: string, payload: DeclineSignerPayload) {
        return axios.delete(`${SIGNING_DOCUMENT_SIGNER_PUBLIC_ENDPOINT}/${publicId}`, { data: payload })
    }

    public getSignableDocuments(filter: SignableDocumentsFilter) {
        const queryString = QueryUtils.objectToQueryString(filter);
        return axios.get<Page<SignableDocumentsDTO>>(`${SIGNING_ENDPOINT}${queryString}`, {headers: authHeader()})
    }

    public createPrefilledCompanyDocument(payload: CreatePrefilledCompanyDocumentPayload){
        return axios.post(SIGNING_PREFILLED_COMPANY_ENDPOINT, payload, {headers: authHeader()});
    }

}
